*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}

:root[data-theme="light"] {
  --color-sidebar: var(--color-white250);
  --color-layer1: var(--color-white200);
  --color-layer2: 0deg 0% 96%;
  --color-layer3: var(--color-white10);
  --color-text_unselected: 240deg 2% 41%;
  --color-text_unselected_secondary: 218deg 3% 55%;
  --color-text_selected: 221deg 25% 12%;
  --color-icon_selected: 221deg 25% 12%;
  --color-icon_unselected: 218deg 3% 55%;
  --color-border_selected: var(--color-white10);
  --color-border_selected2: var(--color-white100);
  --color-border_unselected: 0deg 0% 87%;
  --color-hover: 0deg 0% 80%;
  --color-active: 0deg 0% 70%;
  --color-active_hover: 0deg 0% 80%;
  --color-border_unselected_secondary: 0deg 0% 100%;
  --color-outline_unselected: 0deg 0% 80%;
  --color-outline_hover: 218deg 3% 55%;
  --color-button: 0deg 0% 80%;
  --color-button_enable: 225deg 26% 9%;
  --color-button_hover: linear-gradient(0deg, var(--color-button_enable), var(--color-button_enable)), linear-gradient(0deg, #ffffff1f, #ffffff1f);
  --color-button_focus: linear-gradient(0deg, var(--color-button_enable), var(--color-button_enable)), linear-gradient(0deg, #ffffff3d, #ffffff3d);
  --color-blue: 202deg 69% 50%;
  --color-bshadow1: 201deg 27% 90%;
  --color-bshadow2: 202deg 41% 83%;
  --color-green: 144deg 59% 51%;
  --color-gshadow1: 143deg 41% 91%;
  --color-gshadow2: 144deg 45% 85%;
  --color-white0: 0deg 0% 100% 0;
  --color-white10: 0deg 0% 100%;
  --color-white100: 0deg 0% 96%;
  --color-white200: 0deg 0% 93%;
  --color-white250: 0deg 0% 87%;
  --color-white300: 0deg 0% 80%;
  --color-white350: 0deg 0% 70%;
  --color-websraper: 44deg 100% 50%;
  --color-dataentry: #de82ff;
  --color-generalchat: #50b2ec;
  --color-conversationalbi: #6ee99f;
}

:root[data-theme="dark"] {
  --color-sidebar: 221deg 27% 9%;
  --color-layer1: 221deg 25% 12%;
  --color-layer2: 221deg 19% 17%;
  --color-layer3: 219deg 19% 11%;
  --color-text_unselected: 224deg 6% 64%;
  --color-text_unselected_secondary: 240deg 2% 41%;
  --color-text_selected: 0deg 0% 96%;
  --color-icon_selected: 0deg 0% 96%;
  --color-icon_unselected: 0deg 0% 47%;
  --color-border_selected: var(--color-blue900);
  --color-border_selected2: 221deg 19% 17%;
  --color-border_unselected: 221deg 27% 9%;
  --color-hover: 221deg 19% 17%;
  --color-active: 218deg, 3%, 55%;
  --color-active_hover: 222deg 9% 28%;
  --color-border_unselected_secondary: 0deg 0% 100%;
  --color-outline_unselected: 219deg 11% 25%;
  --color-outline_hover: 240deg 2% 41%;
  --color-button: 0deg 0% 96%;
  --color-button_enable: var(--color-white200);
  --color-button_hover: linear-gradient(0deg, var(--color-button_enable), var(--color-button_enable)), linear-gradient(0deg, #0000001f, #0000001f);
  --color-button_focus: linear-gradient(0deg, var(--color-button_enable), var(--color-button_enable)), linear-gradient(0deg, #ffffff3d, #ffffff3d);
  --color-blue: 202deg 80% 62%;
  --color-blue900: 212deg 28% 21%;
  --color-bshadow1: 207deg 34% 25%;
  --color-bshadow2: 212deg 28% 21%;
  --color-green: 144deg 82% 70%;
  --color-gshadow1: 168deg 22% 25%;
  --color-gshadow2: 167deg 19% 18%;
  --color-white0: 0deg 0% 100% 0;
  --color-white10: 0deg 0% 100%;
  --color-white100: 0deg 0% 96%;
  --color-white200: 0deg 0% 93%;
  --color-white250: 0deg 0% 87%;
  --color-white300: 0deg 0% 80%;
  --color-white350: 0deg 0% 70%;
  --color-websraper: 44deg 100% 50%;
  --color-dataentry: #de82ff;
  --color-generalchat: #50b2ec;
  --color-conversationalbi: #6ee99f;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.-left-1 {
  left: -.25rem;
}

.-right-2 {
  right: -.5rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-8 {
  top: -2rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.left-1\/2 {
  left: 50%;
}

.left-16 {
  left: 4rem;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-5 {
  right: 1.25rem;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: .5rem;
}

.top-5 {
  top: 1.25rem;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.m-auto {
  margin: auto;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-\[150px\] {
  margin-left: 150px;
  margin-right: 150px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-mr-3 {
  margin-right: -.75rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-3px\] {
  margin-top: -3px;
}

.mt-\[60px\] {
  margin-top: 60px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.\!inline {
  display: inline !important;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-4\/6 {
  height: 66.6667%;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-7 {
  height: 1.75rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-\[168\.69px\] {
  height: 168.69px;
}

.h-\[230px\] {
  height: 230px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[440px\] {
  height: 440px;
}

.h-\[460px\] {
  height: 460px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[544px\] {
  height: 544px;
}

.h-\[60vh\] {
  height: 60vh;
}

.h-\[92px\] {
  height: 92px;
}

.h-\[calc\(100\%-50px\)\] {
  height: calc(100% - 50px);
}

.h-\[calc\(100\%-60px\)\] {
  height: calc(100% - 60px);
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-36 {
  max-height: 9rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-20 {
  min-height: 5rem;
}

.min-h-\[200px\] {
  min-height: 200px;
}

.min-h-\[4\.5rem\] {
  min-height: 4.5rem;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[80vh\] {
  min-height: 80vh;
}

.min-h-fit {
  min-height: fit-content;
}

.min-h-screen {
  min-height: 100vh;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/5 {
  width: 40%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-96 {
  width: 24rem;
}

.w-\[119px\] {
  width: 119px;
}

.w-\[120\.57px\] {
  width: 120.57px;
}

.w-\[28\%\] {
  width: 28%;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[34\%\] {
  width: 34%;
}

.w-\[37\%\] {
  width: 37%;
}

.w-\[420px\] {
  width: 420px;
}

.w-\[490px\] {
  width: 490px;
}

.w-\[500px\] {
  width: 500px;
}

.w-\[530px\] {
  width: 530px;
}

.w-\[540px\] {
  width: 540px;
}

.w-\[548px\] {
  width: 548px;
}

.w-\[620px\] {
  width: 620px;
}

.w-\[63\%\] {
  width: 63%;
}

.w-\[712px\] {
  width: 712px;
}

.w-\[80vh\] {
  width: 80vh;
}

.w-\[92px\] {
  width: 92px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-6 {
  min-width: 24px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[320px\] {
  min-width: 320px;
}

.max-w-\[150px\] {
  max-width: 150px;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-center {
  justify-items: center;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-active_hover > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: hsl(var(--color-active_hover) / var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-active) / var(--tw-border-opacity));
}

.border-active_hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-active_hover) / var(--tw-border-opacity));
}

.border-border_selected {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-border_selected) / var(--tw-border-opacity));
}

.border-border_unselected {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-border_unselected) / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-layer2 {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-layer2) / var(--tw-border-opacity));
}

.border-outline_unselected {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-outline_unselected) / var(--tw-border-opacity));
}

.border-text_selected {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-text_selected) / var(--tw-border-opacity));
}

.border-b-border_selected {
  --tw-border-opacity: 1;
  border-bottom-color: hsl(var(--color-border_selected) / var(--tw-border-opacity));
}

.bg-active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-active) / var(--tw-bg-opacity));
}

.bg-active_hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-active_hover) / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/\[0\.65\] {
  background-color: #000000a6;
}

.bg-border_selected {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-border_selected) / var(--tw-bg-opacity));
}

.bg-border_selected2 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-border_selected2) / var(--tw-bg-opacity));
}

.bg-border_unselected {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-border_unselected) / var(--tw-bg-opacity));
}

.bg-button {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-button) / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-green) / var(--tw-bg-opacity));
}

.bg-icon_unselected {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-icon_unselected) / var(--tw-bg-opacity));
}

.bg-layer1 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-layer1) / var(--tw-bg-opacity));
}

.bg-layer2 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-layer2) / var(--tw-bg-opacity));
}

.bg-layer3 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-layer3) / var(--tw-bg-opacity));
}

.bg-outline_hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-outline_hover) / var(--tw-bg-opacity));
}

.bg-outline_unselected {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-outline_unselected) / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-sidebar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-sidebar) / var(--tw-bg-opacity));
}

.bg-text_selected {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-text_selected) / var(--tw-bg-opacity));
}

.bg-title_conversationalbi {
  background-color: var(--color-conversationalbi);
}

.bg-title_dataentry {
  background-color: var(--color-dataentry);
}

.bg-title_generalchat {
  background-color: var(--color-generalchat);
}

.bg-title_webscraper {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-websraper) / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-ad {
  background-image: url("ad.f53f18a6.svg");
}

.bg-analytics {
  background-image: url("analytics.414ef45c.svg");
}

.bg-athena {
  background-image: url("athena.6110d8d6.png");
}

.bg-billing {
  background-image: url("billing.6b0e4f94.svg");
}

.bg-bluecheck {
  background-image: url("checkcircle.31b731af.svg");
}

.bg-blueplus {
  background-image: url("plus.6409c974.svg");
}

.bg-blueupload {
  background-image: url("upload.cf452664.svg");
}

.bg-brightstep1 {
  background-image: url("brightstep1.cc09ab49.svg");
}

.bg-checked {
  background-image: url("checked.eac13877.svg");
}

.bg-conversationalbi {
  background-image: url("conversation_bi.6b2ae9e2.svg");
}

.bg-darkequalizer {
  background-image: url("equalizer.2e6d38c1.svg");
}

.bg-darkgear {
  background-image: url("gearsix.6c1dadf6.svg");
}

.bg-darkgroups {
  background-image: url("groups.8f9925f9.svg");
}

.bg-darkquestion {
  background-image: url("question.02255c30.svg");
}

.bg-darksundim {
  background-image: url("sundim.f6de5005.svg");
}

.bg-darkthumbsdown {
  background-image: url("thumbsdown.ad6b9137.svg");
}

.bg-darkthumbsup {
  background-image: url("thumbsup.c858d689.svg");
}

.bg-database {
  background-image: url("database.0710fc5b.svg");
}

.bg-dataentry {
  background-image: url("data_entry.ff86fe9b.svg");
}

.bg-dataset {
  background-image: url("dataset.0add06af.svg");
}

.bg-envelope {
  background-image: url("envelope.8dac9afd.svg");
}

.bg-eye {
  background-image: url("eye.94355aac.svg");
}

.bg-eyeslash {
  background-image: url("eyeslash.e59a94f5.svg");
}

.bg-feedback {
  background-image: url("feedback.9ff81b17.svg");
}

.bg-generalchat {
  background-image: url("general_chat.01b15dfe.svg");
}

.bg-google {
  background-image: url("google.2cd29feb.svg");
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-lightclose {
  background-image: url("close.0639ecee.svg");
}

.bg-lightcopy {
  background-image: url("copy.b013d895.svg");
}

.bg-lightfilledthumbsdown {
  background-image: url("lightfilledthumbsdown.db557995.svg");
}

.bg-lightfilledthumbsup {
  background-image: url("lightfilledthumbsup.bb919ff2.svg");
}

.bg-lightlegion {
  background-image: url("legion.71f2f34c.svg");
}

.bg-lightsearch {
  background-image: url("search.0503337e.svg");
}

.bg-lightsignout {
  background-image: url("signout.54057df2.svg");
}

.bg-paperplane {
  background-image: url("paperplane.e1159b77.svg");
}

.bg-picture {
  background-image: url("picture.2a710a98.svg");
}

.bg-setting {
  background-image: url("setting.bb40d9ff.svg");
}

.bg-step2 {
  background-image: url("step2.24df0406.svg");
}

.bg-step3 {
  background-image: url("step3.25d96fd7.svg");
}

.bg-step4 {
  background-image: url("step4.17e04bba.svg");
}

.bg-trash {
  background-image: url("trash.0018b803.svg");
}

.bg-webscraper {
  background-image: url("web_scraper.af80ea9f.svg");
}

.from-border_selected {
  --tw-gradient-from: hsl(var(--color-border_selected) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: hsl(var(--color-border_selected) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-layer1 {
  --tw-gradient-to: hsl(var(--color-layer1) / 1) var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.fill-icon_selected {
  fill: hsl(var(--color-icon_selected) / 1);
}

.fill-icon_unselected {
  fill: hsl(var(--color-icon_unselected) / 1);
}

.stroke-gray-500 {
  stroke: #6b7280;
}

.stroke-icon_selected {
  stroke: hsl(var(--color-icon_selected) / 1);
}

.stroke-icon_unselected {
  stroke: hsl(var(--color-icon_unselected) / 1);
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-9 {
  padding: 2.25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-roboto {
  font-family: Roboto, sans-serif;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[21px\] {
  font-size: 21px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.leading-4 {
  line-height: 1rem;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.\!text-text_selected {
  --tw-text-opacity: 1 !important;
  color: hsl(var(--color-text_selected) / var(--tw-text-opacity)) !important;
}

.text-active {
  --tw-text-opacity: 1;
  color: hsl(var(--color-active) / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue {
  --tw-text-opacity: 1;
  color: hsl(var(--color-blue) / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-green {
  --tw-text-opacity: 1;
  color: hsl(var(--color-green) / var(--tw-text-opacity));
}

.text-layer1 {
  --tw-text-opacity: 1;
  color: hsl(var(--color-layer1) / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-sidebar {
  --tw-text-opacity: 1;
  color: hsl(var(--color-sidebar) / var(--tw-text-opacity));
}

.text-text_selected {
  --tw-text-opacity: 1;
  color: hsl(var(--color-text_selected) / var(--tw-text-opacity));
}

.text-text_unselected {
  --tw-text-opacity: 1;
  color: hsl(var(--color-text_unselected) / var(--tw-text-opacity));
}

.text-text_unselected_secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--color-text_unselected_secondary) / var(--tw-text-opacity));
}

.text-title_conversationalbi {
  color: var(--color-conversationalbi);
}

.text-title_dataentry {
  color: var(--color-dataentry);
}

.text-title_generalchat {
  color: var(--color-generalchat);
}

.text-title_webscraper {
  --tw-text-opacity: 1;
  color: hsl(var(--color-websraper) / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-auto {
  text-underline-offset: auto;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

html, body, label, span {
  font-family: Roboto;
}

input::-ms-reveal {
  display: none;
}

input:-webkit-autofill {
  transition-delay: 9999s;
}

input:-webkit-autofill:hover {
  transition-delay: 9999s;
}

input:-webkit-autofill:focus {
  transition-delay: 9999s;
}

input:-webkit-autofill:active {
  transition-delay: 9999s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-text_selected) !important;
}

:root {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

.placeholder\:text-text_unselected::placeholder {
  --tw-text-opacity: 1;
  color: hsl(var(--color-text_unselected) / var(--tw-text-opacity));
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:left-\[2px\]:after {
  content: var(--tw-content);
  left: 2px;
}

.after\:top-\[2px\]:after {
  content: var(--tw-content);
  top: 2px;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:bg-border_unselected:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-border_unselected) / var(--tw-bg-opacity));
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focus-within\:border-active:focus-within {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-active) / var(--tw-border-opacity));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-active:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-active) / var(--tw-border-opacity));
}

.hover\:bg-active:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-active) / var(--tw-bg-opacity));
}

.hover\:bg-active\/90:hover {
  background-color: hsl(var(--color-active) / .9);
}

.hover\:bg-border_unselected:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-border_unselected) / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.hover\:bg-layer1:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-layer1) / var(--tw-bg-opacity));
}

.hover\:bg-layer2:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-layer2) / var(--tw-bg-opacity));
}

.hover\:bg-layer3:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-layer3) / var(--tw-bg-opacity));
}

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.hover\:text-active_hover:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--color-active_hover) / var(--tw-text-opacity));
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.hover\:text-text_selected:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--color-text_selected) / var(--tw-text-opacity));
}

.hover\:text-text_unselected:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--color-text_unselected) / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:border-active:focus {
  --tw-border-opacity: 1;
  border-color: hsl(var(--color-active) / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.peer:checked ~ .peer-checked\:bg-outline_hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-outline_hover) / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:after\:translate-x-8:after {
  content: var(--tw-content);
  --tw-translate-x: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

:is(.dark .dark\:border-gray-600) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-gray-700) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-athena) {
  background-image: url("athena.6110d8d6.png");
}

:is(.dark .dark\:bg-billing) {
  background-image: url("billing.6b0e4f94.svg");
}

:is(.dark .dark\:bg-close) {
  background-image: url("close.52941f7a.svg");
}

:is(.dark .dark\:bg-copy) {
  background-image: url("copy.28db75ad.svg");
}

:is(.dark .dark\:bg-darkfeedback) {
  background-image: url("feedback.8df5d3d8.svg");
}

:is(.dark .dark\:bg-darksignout) {
  background-image: url("signout.4eac858f.svg");
}

:is(.dark .dark\:bg-equalizer) {
  background-image: url("equalizer.1d9b26d0.svg");
}

:is(.dark .dark\:bg-filledthumbsdown) {
  background-image: url("filledthumbsdown.829b7aa6.svg");
}

:is(.dark .dark\:bg-filledthumbsup) {
  background-image: url("filledthumbsup.53b890df.svg");
}

:is(.dark .dark\:bg-groups) {
  background-image: url("groups.aaed1900.svg");
}

:is(.dark .dark\:bg-legion) {
  background-image: url("legion.5d5c8ebd.svg");
}

:is(.dark .dark\:bg-lightpaperplane) {
  background-image: url("paperplane.996af074.svg");
}

:is(.dark .dark\:bg-lightpicture) {
  background-image: url("picture.f6066dab.svg");
}

:is(.dark .dark\:bg-lightsetting) {
  background-image: url("setting.bb40d9ff.svg");
}

:is(.dark .dark\:bg-step1) {
  background-image: url("step1.a9337500.svg");
}

:is(.dark .dark\:text-gray-300) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-500) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:bg-gray-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-800:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:text-gray-200:hover) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 768px) {
  .md\:w-52 {
    width: 13rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }
}

.\[\&\>\*\:hover\]\:bg-layer1 > :hover {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--color-layer1) / var(--tw-bg-opacity));
}
/*# sourceMappingURL=index.e3d5ee45.css.map */
